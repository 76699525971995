$(document).ready(function () {
    $('#tabs-feeds').each(function () {

        //// Tabs / Accordion
        // vars
        let $tabs = $(this).children('.tabs');
        let $accordion = $(this).find('.accordion')
        // init tabs
        $tabs.on('click', '.nav a', function (e) {
            e.preventDefault();
            $(this).tab('show');
        });
        // init accordion
        $accordion.find('.panel-collapse')
            .on('show.bs.collapse', function () {
                $(this).siblings('.panel-heading').addClass('active');
            })
            .on('hide.bs.collapse', function () {
                $(this).siblings('.panel-heading').removeClass('active');
            });
    });

});
