/* Clamp / DotDotDot
 ------------------------------------------------------------------------ */
;$(() => {
    let debug = false;
    $('[data-clamp]').each(function () {
        let $this = $(this);
        let value = $this.data('clamp');
        if ($.isNumeric(value)) {
            if (value === 1) {
                if (debug) console.log('Skipping: [data-clamp=1]');
            } else {
                if (debug) console.log('Error: exceeded [data-clamp] value.');
            }
        } else {
            if (value.indexOf('px') > -1) {
                $this.dotdotdot({
                    height: parseInt(value),
                    watch: true
                });
                if (debug) console.log('Added: height="' + value + 'px"');
            } else if (value.indexOf('%') > -1) {
                if (debug) console.log('Error: [data-clamp] percentages not implemented yet.');
            } else {
                if (debug) console.log('Error: invalid [data-clamp] value.');
            }
        }
    });
});
