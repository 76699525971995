$(document).ready(() => {

    if ($('#case-study-grid').length > 0) {

        // initial Isotope init
        let isotope = $('.isotope-grid').isotope({
            itemSelector: '.isotope-item',
            masonry: {
                columnWidth: '.isotope-sizer'
            },
            percentPosition: true
        });

        isotope.imagesLoaded().progress(() => {
            isotope.isotope('layout');
        });

        // get URL variables
        function getUrlVars() {
            let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            let vars = {};
            let i = 0;
            let hash;
            for (i; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                $.extend(vars, {
                    [hash[0]]: hash[1]
                });
            }
            return vars;
        }

        // jQuery DOM objects
        let $form = $('#isotope-filters');
        let $filters = $form.find('select');

        // init history state
        let state = getUrlVars();
        History.replaceState(state, null, window.location.href);

        // select change handler
        $filters.on('change', (event) => {
            let $currentTarget = $(event.currentTarget);
            if ($currentTarget.val()) {
                state[$currentTarget.attr('name')] = $currentTarget.val();
            } else {
                delete state[$currentTarget.attr('name')];
            }
            History.pushState(state, null, '?' + $.param(state));
        });

        // reset handler
        $form.on('reset', (event) => {
            state = {};
            History.pushState(state, null, '?');
        });

        // set filters + init
        function setFilters(state) {
            $filters.each((index, element) => {
                $(element).val(state[$(element).attr('name')]);
            });
        }
        setFilters(state);

        // filter isotope objects
        function filterIsotope(state) {
            let results = isotope.isotope('getItemElements');
            let noResult = results.pop();
            // business filter
            if (state.business) {
                results = results.filter((item) => {
                    return ($.inArray(state.business, $(item).data('businesses')) >= 0);
                });
            }
            // service filter
            if (state.service) {
                results = results.filter((item) => {
                    return ($.inArray(state.service, $(item).data('services')) >= 0);
                });
            }
            // market filter
            if (state.market) {
                results = results.filter((item) => {
                    return ($.inArray(state.market, $(item).data('markets')) >= 0);
                });
            }
            // no results
            if (results.length === 0) {
                results = noResult;
            }
            // return results
            return results;
        }

        // state change handler + init
        $(window).on('statechange', (event) => {
            state = History.getState().data;
            setFilters(state);
            isotope.isotope({
                filter: filterIsotope(state)
            });
        }).trigger('statechange');

        $(window).load((event) => {
            isotope.isotope();
        });

    }

});
