$(document).ready(() => {

    let $footer = $('.ccm-page > footer').first();
    let $navigation = $footer.find('.navigation').first();

    $navigation.find('a').each(function () {
        if (window.location.href === $(this).attr('href')) {
            $(this).addClass('active');
        }
    });

});
